@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.footerWrapper {
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 32px;
  @include responsive("smDown") {
    flex-direction: column;
    gap: 15px;
    height: fit-content;
    padding-top: 48px;
    padding-bottom: 32px;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 36px;
    @include responsive("smDown") {
      flex-direction: column;
      gap: 15px;
    }
    .links {
      font-family: "Inter Meduim";
      font-size: 14px;
      line-height: 17px;
      color: $black-2;
      text-decoration: none;
    }
    .socialLinks {
      display: flex;
      align-items: center;
      gap: 18px;
      a {
        color: $black-2;
      }
    }
  }
  .copyRightText {
    color: $input-placeholder-color;
  }
}
