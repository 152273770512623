@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.isActive {
  @include responsive("mdDown") {
    display: block !important;
    width: 100% !important;
    .sidebarBurger {
      display: flex !important;
      align-items: center;
      justify-content: space-between !important;
      .closeIcon {
        display: block !important;
        padding: 20px;
      }
      i {
        width: 22px;
        height: 17px;
        font-size: 20px;
      }
    }
  }
}

.sidebar {
  width: 260px;
  background-color: $white-0;

  @include responsive("mdDown") {
    display: none;
  }
  .closeIcon {
    display: none;
  }
  .icon {
    display: flex;
    justify-content: flex-start;
    height: 80px;
    margin-left: 10px;
    margin-bottom: 20px;
  }
  .sidebarContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding-left: 20px;
    path {
      fill: #525f7f;
    }

    .active {
      color: $black-3 !important;
      path {
        fill: $black-3 !important;
      }
    }

    .sidebarItem {
      display: flex;
      cursor: pointer;
      align-items: center;
      gap: 24px;
      height: 44px;
      color: $blue-2;
      i {
        width: 22px;
        height: 17px;
      }
    }
  }
}
