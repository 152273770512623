@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.breadcrumbs {
  display: flex;
  text-align: left;
  text-transform: capitalize;
  color: $white-0;

  .routes {
    margin-left: 24px;
    display: flex;
    align-items: center;

    .route {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 7px;
      margin-left: 7px;
    }
  }
}