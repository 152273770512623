@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.modalWrapper {
  max-width: 512px;
  .wrapper {
    .titleWrapper {
      @include responsive("mdDown") {
        display: none;
      }
    }
    .form {
      .companyAddressDetails {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-column-gap: 16px;
        @include responsive("mdDown") {
          grid-template-columns: 1fr;
        }
      }
      .actionButtons {
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 24px;
        .submitButton {
          background-color: $blue-0 !important;
        }
      }
      .additionalInfo {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-column-gap: 16px;
      }
      .repDetails {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;
        margin-bottom: 16px;
        @include responsive("mdDown") {
          grid-template-columns: 1fr;
        }
      }

      .formGroupCheckBox {
        margin-bottom: 24px !important;
        font-size: 14px;
        @include responsive("mdDown") {
          margin-bottom: 28px !important;
        }
      }
      .formGroup {
        text-align: start;
        .inputGroupStyle {
          box-shadow: none;
          border: 1px solid $white-4;
        }
        .label {
          font-family: "Inter Meduim";
          font-size: 14px;
          color: $blue-2;
          margin-bottom: 8px !important;
          span {
            color: $danger-btn-color;
          }
        }
        label {
          font-family: "Inter Meduim";
          font-size: 14px;
          color: $blue-2;
          margin-bottom: 8px !important;
        }
      }
    }
  }
}