@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.settingsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  @include responsive("mdDown") {
    grid-template-columns: 1fr;
  }
}

.breadcrumbWrapper {
  margin-bottom: 36px;
  gap: 24px;
  display: flex;
  align-items: center;
  color: $white-0;
}