@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  height: 100%;
  padding-bottom: 100px;
  @include responsive("smDown") {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
  .card {
    max-width: 446px;
    .signupInputs {
      .formGroup {
        @include responsive("smDown") {
          margin-bottom: 12px !important;
        }
      }
      .formGroupCheckBox {
        font-size: 14px;
        @include responsive("smDown") {
          margin-top: 12px !important;
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .signupHeader {
    padding-top: 36px;
    padding-bottom: 40px;

    img {
      height: 136px;
      width: 136px;
      @include responsive("smDown") {
        height: 102px;
        width: 102px;
      }
    }

    p {
      font-family: "Inter Meduim";
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      color: $black-2;
    }
    @include responsive("smDown") {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
      p {
        display: none;
      }
    }
  }

  .checkEmailPage {
    padding-bottom: 100px;
  }

  .footer {
    font-weight: 600;
    p {
      color: $black-2;
    }
  }
}

.confirmWrapper {
  max-width: 512px;
  padding-bottom: 60px;

  .icon {
    padding-top: 8px;
    padding-bottom: 24px;
  }

  .content {
    .title {
      color: $black-1;
      h3 {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .details {
      color: $black-0;
      p {
        font-family: "Inter Light";
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;

        span {
          font-family: "Inter Meduim";
          font-weight: 400;
        }

        a {
          color: $link;
        }
      }
    }
  }
}
