@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.breadcrumbWrapper {
  margin-bottom: 36px;
  gap: 24px;
  display: flex;
  align-items: center;
  color: $white-0;
}

.wrapper {
  .reportOrder {
    margin-bottom: 32px;
  }
  .bottomCards {
    margin-top: 32px;
    display: flex;
    gap: 32px;

    @include responsive("mdDown") {
      flex-direction: column;
    }
  }
}
