@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  height: 100%;
  @include responsive("smDown") {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
  .card {
    max-width: 446px;
    .checkEmailContent {
      padding-top: 16px;
      .alertWrapper {
        width: 100%;
        margin-bottom: 32px;
        margin-top: 24px;
      }
      p {
        font-size: 18px;
        line-height: 24px;
      }
      .resendButton {
        margin-top: 32px;
        margin-bottom: 24px;
      }
    }
  }

  .forgotPasswordWrapper {
    padding-top: 61px;
    padding-bottom: 40px;
    @include responsive("smDown") {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .logo {
      display: none;
      @include responsive("smDown") {
        display: block;
      }
      img {
        height: 136px;
        width: 136px;
        @include responsive("smDown") {
          height: 102px;
          width: 102px;
        }
      }
    }

    .title {
      h3 {
        font-family: "Inter Meduim";
        font-size: 32px;
        line-height: 44px;
      }

      p {
        font-family: "Inter SemiBold";
        font-size: 18px;
        line-height: 32px;
      }
      @include responsive("smDown") {
        display: none;
      }
    }

    p {
      font-family: "Inter Meduim";
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      color: $black-2;
    }
  }

  .checkEmailPage {
    padding-bottom: 100px;
  }

  .footer {
    font-weight: 600;
  }
  .link {
    display: block;
  }

  .forgotPasswordInput {
    .submitButtonWrapper {
      margin-bottom: 8px;
      margin-top: 16px;
      .submitButton {
        width: 150px;
      }
    }
  }
}
