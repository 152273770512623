@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.toastContainer {
  position: fixed;
  z-index: 9999999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px 24px;
  border-radius: 6px;
  @include responsive("smDown") {
    width: 90% !important;
    left: 50%  !important;
    transform: translate(-50%, 0)  !important;
  }
  .icon {
    padding-right: 20px;
  }

  button {
    background: none;
    border: none;
    color: $white-0;
    font-size: 20px;
    display: flex;
    align-items: center;
    font-weight: 900;
    align-self: baseline;
    outline: none;
    padding: 0;
    cursor: pointer;
    border-radius: 8px;
  }

  .toastWrapper {
    display: flex;
    align-items: baseline;
    color: $white-0;
    opacity: 0.85;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5px;

    .title {
      font-family: "Inter SemiBold";
      font-weight: 600;
      line-height: 27px;
      font-size: 16px;
      span {
        font-family: "Inter Bold";
        font-weight: 700;
        margin-right: 3px;
      }
    }

    .content {
      font-family: "Inter Bold";
      font-weight: 400;
      line-height: 20px;
      font-size: 14px;
    }
  }
}

.top-md {
  max-width: 388px;
  top: 12px;
  left: calc(50% - 194px);
}

.top-lg {
  max-width: 700px;
  top: 12px;
  left: calc(50% - 350px);
}

.bottom-md {
  max-width: 388px;
  bottom: 12px;
  left: calc(50% - 194px);
}

.bottom-lg {
  max-width: 700px;
  bottom: 12px;
  left: calc(50% - 322px);
}

.top-right-md {
  max-width: 388px;
  top: 12px;
  right: 12px;
  margin-left: 12px;
}

.top-right-lg {
  max-width: 700px;
  top: 12px;
  right: 12px;
  margin-left: 12px;
}

.top-left-md {
  max-width: 388px;
  top: 12px;
  left: 12px;
  margin-left: 12px;
}

.top-left-lg {
  max-width: 700px;
  top: 12px;
  left: 12px;
  margin-left: 12px;
}

.bottom-right-md {
  max-width: 388px;
  bottom: 12px;
  right: 12px;
  margin-left: 12px;
}

.bottom-right-lg {
  max-width: 700px;
  bottom: 12px;
  right: 12px;
  margin-left: 12px;
}

.bottom-left-md {
  max-width: 388px;
  bottom: 12px;
  left: 12px;
  margin-left: 12px;
}

.bottom-left-lg {
  max-width: 700px;
  bottom: 12px;
  left: 12px;
  margin-left: 12px;
}

@keyframes slide-in {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

.default {
  background-color: $toast-default;
}

.success {
  background-color: $toast-success;
}

.danger {
  background-color: $toast-danger;
}
