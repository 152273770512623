@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.card {
  width: 100%;
  margin: 0 auto;
  background-color: $white-2;
  box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
  border-radius: 6px;
  border: 0;
  .cardBody {
    padding: 32px 29px;
    @include responsive("smDown") {
      padding: 24px 18px;
    }
  }
  .title {
    font-family: "Inter";
    margin-top: 48px;
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 24px;
    @include responsive("smDown") {
      margin-top: 24px;
      margin-bottom: 0px;
    }
  }
  .footer {
    font-family: "Inter";
    border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
    padding: 32px 29px 48px 29px;
  }
}
