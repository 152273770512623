@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.mainWrapper {
  max-width: 980px;
  display: flex;
  height: 780px;
  border-radius: 6px;
  @include responsive("mdDown") {
    height: 100%;
    flex-direction: column;
    width: 100%;
  }

  .progressBarMobile {
    display: none;
    @include responsive("mdDown") {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .progressBar {
      margin-bottom: 32px;
      display: flex;
      gap: 4px;
      .mobileBarWrapper {
        width: 100%;
        p {
          color: $input-placeholder-color;
        }
        .bar {
          border-radius: 1px;
          width: 100%;
          height: 5px;
          background: $gray-3;
        }
      }
      .active {
        .bar {
          border-radius: 1px;
          width: 100%;
          height: 5px;
          background: linear-gradient(90deg, #2dce89 0%, #2dcecc 100%);
        }
        p {
          color: $black-0;
        }
      }
    }
  }

  .progressSidebar {
    padding: 48px 32px;
    position: relative;
    text-align: left;
    color: $white-0;
    width: 40%;
    border-radius: 6px 0 0 6px;
    @include responsive("mdDown") {
      display: none;
    }

    .progressBar {
      margin-bottom: 32px;
      display: flex;
      gap: 4px;
      .bar {
        border-radius: 4px;
        width: 100%;
        height: 5px;
        background-color: $white-0;
        opacity: 0.2;
      }
      .active {
        opacity: 1;
      }
    }
  }

  .formWrapper {
    overflow: auto;
    padding: 48px;
    background-color: $white-2;
    width: 60%;
    border-radius: 0 6px 6px 0;
    height: 780px;
    @include responsive("mdDown") {
      height: 100%;
      width: 100%;
      padding: 16px 1px;
      background-color: transparent;
    }
  }

  .summaryWrapper {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 32px;
  }
}
