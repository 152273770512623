@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.modalWrapper {
  max-width: 900px;
  position: relative;
  .closeIcon {
    position: absolute;
    top: 5px;
    right: 15px;
    cursor: pointer;
  }
}
