@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
.caret {
  color: $input-placeholder-color;
  position: absolute;
  right: 8px;
}
.wrapper {
  .titleWrapper {
    @include responsive("mdDown") {
      display: none;
    }
  }
  .form {
    .companyAddressDetails {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      grid-column-gap: 16px;
      @include responsive("mdDown") {
        grid-template-columns: 1fr;
      }
    }
    .nextStep {
      margin-top: 32px;
    }
    .additionalInfo {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-column-gap: 16px;
    }
    .nameAndEmail {
      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-column-gap: 16px;
      margin-bottom: 16px;
      @include responsive("mdDown") {
        grid-template-columns: 1fr;
      }
    }

    .formGroupCheckBox {
      margin-bottom: 32px !important;
      font-size: 14px;
      @include responsive("mdDown") {
        margin-bottom: 28px !important;
      }
    }
    .formGroup {
      text-align: start;
      .inputGroupStyle {
        box-shadow: none;
        border: 1px solid $white-4;
      }
      .label {
        font-family: "Inter Meduim";
        font-size: 14px;
        color: $blue-2;
        margin-bottom: 8px !important;
        span {
          color: $danger-btn-color;
        }
      }
      label {
        font-family: "Inter Meduim";
        font-size: 14px;
        color: $blue-2;
        margin-bottom: 8px !important;
      }
    }

    .addbtn{
      font-size: 14px;
      line-height: 18px;
    }

    .additionalINfoText{
      font-size: 14px;
      line-height: 18px;
    }

  }
}
