@import "src/assets/scss/colors";

.selectStyling {
  position: relative;
  .leftIcon {
    position: absolute;
    top: 12px;
    left: 15px;
    z-index: 1;
  }

  .rightIcon {
    position: absolute;
    top: 12px;
    right: 15px;
    z-index: 1;
  }

  &.hasLeftIcon {
    :global {
      .rselect__control {
        padding-left: 35px;
      }
    }
  }
  &:focus-visible {
    outline: none;
  }
  .reactSelect {
    &:focus-visible {
      outline: none;
    }
  }
  :global {
    .rselect__menu {
      z-index: 20;
    }
    .rselect__control {
      border: 1px solid $white-4;
      border-radius: 4px;
      font-size: 16px;
      padding: 5px 1px;
      color: $black-2;

      &:hover {
        border-color: $white-4;
      }

      &.rselect__control--is-focused {
        box-shadow: none;
        border-color: $white-4;
      }
    }

    .rselect__placeholder {
      color: $input-placeholder-color;
      font-size: 14px;
    }

    .rselect__single-value,
    .rselect__option {
      color: $black-2;
      font-weight: normal;
      font-size: 14px;
    }

    .rselect__option--is-focused {
      background-color: $white-2;
      color: $black-2;
    }

    .rselect__option--is-selected {
      background-color: $white-4 !important;
      color: $black-2;
    }

    .rselect__option:hover {
      background-color: $white-2;
      color: $black-2;
    }

    .rselect__indicator {
      padding: 0 2px;
    }

    .rselect__indicator-separator,
    .rselect__dropdown-indicator {
      display: none;
    }
  }
}

.isInvalid {
  border-radius: 4px;
  border: 1px solid $warning-btn-color;
  outline: none;
}

.invalidFeedback {
  text-align: left;
  display: flex;
  color: $warning-btn-color;
  font-size: 14px;
  padding: 8px 12px;
}
