@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  .accordionItem {
    margin-bottom: 10px;
    border-radius: 6px;
    overflow: hidden;
    .header {
      color: $blue-1;
      button {
        font-family: "Inter SemiBold";
        font-size: 24px;
        line-height: 30px;
        background-color: $white-0;
        color: $blue-1;
        box-shadow: none;
        @include responsive("mdDown") {
          font-size: 18px;
          line-height: 22px;
        }
      }
      button::after {
        content: "▼";
        font-size: 14px;
        width: unset;
        height: unset;
        background-image: none;
      }
    }
    .body {
      font-size: 18px;
      line-height: 22px;
      div {
        padding-top: 0;
        padding-bottom: 0;
      }
      @include responsive("mdDown") {
        font-size: 16px;
        line-height: 20px;
      }
      color: $black-3;
      text-align: left;
    }
  }
}
