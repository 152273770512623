@import "src/assets/scss/colors.scss";

.modalWrapper {
  max-width: 800px;
  .wrapper {
    padding: 0px;
    .header {
      border-bottom: 1px solid $gray-4;
      padding: 20px 24px;
      .title {
        margin-bottom: 0px;
        color: $blue-1;
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: -0.24px;
      }
    }
    .modalBody {
      padding: 24px;
      .updatedTerms {
        color: $black-0;
        font-size: 14px;
        font-weight: 300;
        line-height: normal;
      }
      .description {
        margin-top: 33px;
        padding: 16px;
        border: 1px solid $gray-4;
        max-height: 408px;
        border-radius: 6px;
        overflow: auto;

        .subText {
          color: $black-3;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px; /* 125% */
          letter-spacing: -0.16px;
        }
        .title {
          color: $black-3;
          font-size: 32px;
          font-weight: 700;
          line-height: 40px; /* 125% */
          letter-spacing: -0.32px;
        }
        .subTitle {
          color: $black-3;
          font-size: 20px;
          font-weight: 600;
          line-height: 24px; /* 120% */
          letter-spacing: -0.2px;
          margin-top: 32px;
        }
        .disclaimerList {
          counter-reset: list-counter;
          list-style-type: none;

          li {
            position: relative;
            counter-increment: list-counter;
            &::before {
              font-size: 16px;
              left: -20px;
              top: -2px;
              position: absolute;
              content: counter(list-counter, lower-alpha) ") ";
            }
          }
        }
      }
    }
    .footer {
      display: flex;
      padding: 0px 24px 24px 24px;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
  }
  ::-webkit-scrollbar {
    width: 6px !important;
    background-color: $white-0 !important;
  }
  ::-webkit-scrollbar-thumb {
    width: 6px !important;
    background-color: #adb5bd !important;
    border-radius: 4px !important;
  }
}
