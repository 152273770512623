@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  cursor: pointer;
  max-width: 340px;
  text-align: left;
  width: fit-content;
  padding: 18px 16px;
  box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
  border-radius: 6px;
  background: $white-0;
  @include responsive("mdDown") {
    margin-top: 10px;
  }
  .subWrapper {
    display: flex;
    gap: 10px;
    .titleText {
      color: $blue-1;
    }

    .orderButton {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border: none;
        border-radius: 50%;
        background: linear-gradient(90deg, #fb6340 0%, #fbb140 100%);
        i {
          color: $white-0;
          font-size: 20px;
        }
      }
    }
  }

  .date {
    margin-bottom: 0;
    color: $blue-2;
  }
}
