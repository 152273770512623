@import "src/assets/scss/breakpoints";
@import "src/assets/scss/colors";

.wrapper {
  padding-top: 153px;
  padding-bottom: 85px;
  background: linear-gradient(324.08deg, #11cdef -24.31%, #1171ef 66.73%);
  @include responsive("mdDown") {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .mainWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    @include responsive("mdDown") {
      grid-template-columns: 1fr;
      height: auto;
      padding-left: 24px;
      padding-right: 24px;
    }
    .PDFWrapper {
      .imgWrapper {
        width: 434px;
        height: 560px;
        @include responsive("xsDown") {
          width: 100%;
          height: 415px;
        }
      }

      .btnWrapper {
        padding-top: 32px;
        display: flex;
        justify-content: center;
        gap: 24px;
        margin: auto;
        @include responsive("mdDown") {
          padding-top: 24px;
        }

        button {
          width: fit-content;
        }

        .arrowIconWrapper {
          svg {
            color: #1171ef;
          }
        }

        .leftBtn {
          background-color: $white-0 !important;
          color: #1171ef;
        }

        .diabledLeftBtn {
          background-color: $white-0 !important;
          color: #212529;
        }

        .rightBtn {
          background-color: $white-0 !important;
          color: #1171ef;
        }

        .diabledRightBtn {
          background-color: $white-0 !important;
          color: #212529;
        }

        .pdfPageTextWrapper {
          margin-top: 10px;
          color: $black-2;
        }
      }
      .pdfTextWrapper {
        margin-top: 32px;
        text-decoration: underline;
        a {
          color: $white-0;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
        }
        @include responsive("mdDown") {
          margin-top: 24px;
        }
      }
    }

    .ReportWrapper {
      text-align: left;
      color: $white-0;
      display: block;
      @include responsive("mdDown") {
        padding-top: 36px;
        margin: auto;
      }

      .textWrapper {
        font-weight: 400;
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 32px;
        @include responsive("mdDown") {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 24px;
        }
      }

      .titleWrapper {
        font-weight: 700;
        margin-bottom: 32px;
        @include responsive("mdDown") {
          margin-bottom: 24px;
        }
      }

      .descriptionWrapper {
        font-weight: 400;
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 32px;
        @include responsive("mdDown") {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 24px;
        }
      }

      .listWrapper {
        font-weight: 700;
        font-size: 22px;
        list-style-type: none;
        line-height: 34px;
        padding-left: 0px;
        @include responsive("mdDown") {
          font-size: 20px;
        }
        .iconWrapper {
          margin-right: 10px;
          svg {
            color: $yellow-1;
          }
        }
      }
    }
  }
}
