@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.paymentMethodWrapper {
  height: fit-content;
  padding: 32px;
  svg {
    font-size: 34px;
    height: 34px;
    width: 34px;
  }
  @include responsive("mdDown") {
    justify-content: center;
    button {
      display: none;
    }
  }
  .noPayment {
    text-align: left;
    margin-bottom: 0;
    color: $input-placeholder-color;
  }
  .paymentContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button {
      background-color: $blue-0;
      &.add {
        background-color: $blue-0 !important;
      }
      &.edit {
        background-color: $blue-0 !important;
      }
      &.onEdit {
        display: none;
      }
      &.delete {
        box-shadow: none;
        background-color: transparent !important;
        border: 1px solid #f5365c;
        color: #f5365c;
      }
    }
  }
  .form {
    margin-top: 24px;
    .label {
      text-align: left;
      color: #525f7f;
      &:after {
        content: " *";
        color: #f5365c;
      }
    }
    .stripeForm {
      :global {
        .StripeElement--focus {
          border-color: $blue-0;
        }
      }
    }
    .formGroup {
      .inputStyle {
        box-shadow: none;
        border: 1px solid #dee2e6;
      }
    }
    .actionButton {
      text-align: left;
      display: flex;
      gap: 16px;
      button {
        font-size: 14px;
        font-family: "Inter Meduim";
        font-weight: 500;
        line-height: 20px;
        padding: 12px 20px;
      }
      .saveButton {
        border: none;
        background-color: $blue-0;
      }
    }
  }
  .trashCan {
    display: none;
    @include responsive("mdDown") {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      padding: 7px 16px;
      background-color: $danger-btn-color;

      svg {
        height: 14px;
        width: 14px;
      }
      path {
        fill: $white-0;
      }
    }
  }
}
