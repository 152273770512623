@import "src/assets/scss/breakpoints";
@import "src/assets/scss/colors";

.wrapper {
  background-color: $white-0;
  height: auto;

  .contentWrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding-top: 60px;
    padding-bottom: 64px;
    @include responsive("mdDown") {
      grid-template-columns: 1fr;
      height: auto;
      width: 90%;
      margin: auto;
      padding: 48px 0px;
    }

    .textWrapper {
      color: $black-3;
      text-align: left;
      @include responsive("mdDown") {
        margin-bottom: 24px;
      }
      .titleWrapper {
        font-weight: 700;
        @include responsive("xsDown") {
          text-align: center;
        }
      }

      .linkWrapper {
        font-size: 24px;
        font-weight: 700;
        color: $link;
        svg {
          margin-left: 15px;
        }
      }
    }

    .imgWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      @include responsive("mdDown") {
        grid-template-columns: 1fr 1fr;
      }

      .imgCls {
        width: 180px;
        height: 180px;
        @include responsive("mdDown") {
          width: 158px;
          height: 158px;
        }
      }
    }
  }
}
