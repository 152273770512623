@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.paginationWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 24px;
  border-top: 2px solid rgba(233, 236, 239,0.5);
  @include responsive("smDown") {
    flex-direction: column;
    gap: 16px;
  }
  .dataShow {
    p {
      color: $input-placeholder-color;
    }
  }
  .paginationElement {
    :global {
      .pagination {
        .active {
          button {
            background-color: $primary-btn-color;
            color: $white-0;
          }
        }
        button {
          color: $gray-1;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    ul {
      display: flex;
      gap: 6px;
      margin-bottom: 0;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50% !important;
      height: 36px;
      width: 36px;
      line-height: initial;
    }
  }
}
