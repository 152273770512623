@import "src/assets/scss/colors";

.cvcExpiry {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-gap: 18px;
  }
  .errorMessage{
    color: $warning-btn-color;
    text-align: left;
    font-size: 14px;
  }