@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  @include responsive("mdDown") {
    display: block;
  }
  .uploadFile {
    display: flex;
    gap: 60px;
    text-align: left;
    @include responsive("xsDown") {
      display: block;
      justify-content: space-between;
    }
    .leftContent {
      margin-top: 25px;
      .fileText {
        color: $blue-3;
        font-weight: 600;
        margin-bottom: 0px;
      }
      .fileSubTitle {
        color: $black-2;
      }
    }
    .formGroup {
      display: none;
    }
    .customButton {
      margin: 20px 0px;
      @include responsive("xsDown") {
        margin: 0px;
      }
    }
    .customButton {
      background-color: $blue-0 !important;
    }
    .updatefileButton {
      background-color: $white-0 !important;
      color: $black-3;
      border: 1px solid $black-3;
    }
  }
  .emailReport {
    display: flex;
    justify-content: space-between;
    text-align: left;
    @include responsive("xsDown") {
      display: block;
    }
    .leftContent {
      margin-top: 25px;
      .emailText {
        color: $blue-3;
        font-weight: 600;
        margin-bottom: 0px;
      }
      .emailSubTitle {
        color: $black-2;
      }
    }
    .customEmailButton {
      margin: 20px 0px;
      @include responsive("xsDown") {
        margin: 0px;
      }
    }
    .emailButton {
      background-color: $blue-0 !important;
    }
    .resentEmailButton {
      background-color: $white-0 !important;
      color: $black-3;
      border: 1px solid $black-3;
    }
    .btnContainer {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }
}
