@import 'src/assets/scss/colors';

.checkboxStyling {
  display: flex;
  align-items: flex-start;
  .outline {
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
  }

  input {
    height: 16px;
    width: 16px;
    border: none;
    box-shadow: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 0;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  input[checked] {
    background-color: $primary-btn-color;
    border-color: $primary-btn-color;
  }
}

.invalidFeedback {
  display: flex;
  color: $warning-btn-color;
  padding: 8px 12px;
}