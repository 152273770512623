@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  height: 100%;
  background: $white-0;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  display: grid;
  @include responsive("mdDown") {
    max-width: 340px;
    margin: auto;
  }

  .subWrapper {
    justify-content: space-between;
    .title {
      font-weight: 700;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      font-size: 14px;
      line-height: 36px;
      text-transform: uppercase;
    }

    .cardTitle {
      background: $blue-0;
      color: $white-0;
    }

    .lowerPlanTitle {
      background: #adb5bd;
      color: $white-0;
    }

    .priceWrapper {
      font-weight: 600;
      font-size: 36px;
      line-height: 40px;
      color: $black-0;
    }

    .monthTextWrapper {
      color: $black-2;
    }

    .price {
      font-size: 24px;
    }
    .planFeatureWrapper {
      .lineWrapper {
        background: #f6f9fc;
        border-bottom: 1px solid $gray-3;
      }
    }

    .discount {
      font-weight: 700;
    }

    .noDiscountWrapper {
      opacity: 0;
    }

    .features {
      font-style: italic;
      text-decoration-line: underline;
      color: $black-3;
      margin-top: 21px;
      font-weight: 400;
    }

    .featureList {
      margin-bottom: 33px;
    }
    .iconWrapper {
      color: $blue-0;
      font-size: 18px;
    }
  }

  .button {
    margin-bottom: 24px !important;
    border-radius: 4px;
    font-size: 14px;
    width: 90%;
    margin: auto;
  }

  .buttonWrapper {
    background: #2dce89;
    border: 1px solid #2dce89;
  }
  .userActivePlan {
    background: #2dce89 !important;
    border: 1px solid #2dce89 !important;
    color: $white-0 !important;
    margin-bottom: 12px !important;
  }
  .subscribedUser {
    color: $black-0;
    background: $white-0;
    border: 1px solid $black-3;
  }
  .disabled {
    opacity: 0.2;
  }
  .lowerbuttonWrapper {
    color: $black-0;
    background: $white-0;
    border: 1px solid $black-3;
  }
  .cancelSubscription {
    padding: 6.5px;
  }
}
