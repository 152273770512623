@import "src/assets/scss/breakpoints";
@import "src/assets/scss/colors";

.mainWrapper {
  background: linear-gradient(324.08deg, #11cdef -24.31%, #1171ef 66.73%);
  height: auto;

  .missionWrapper {
    padding-top: 120px;
    @include responsive("mdDown") {
      padding-top: 48px;
    }

    .titleWrapper {
      font-weight: 700;
      font-size: 64px;
      line-height: 77px;
      color: $white-0;
      margin-bottom: 32px;
      @include responsive("mdDown") {
        font-size: 48px;
        line-height: 64px;
      }
    }

    .subtitleWrapper {
      font-weight: 300;
      font-size: 28px;
      line-height: 36px;
      color: $yellow-1;
      margin-bottom: 32px;
    }

    .descriptionWrapper {
      font-weight: 400;
      color: $white-0;
    }

    .teamTextWrapper {
      font-weight: 700;
      font-size: 48px;
      line-height: 64px;
      color: $white-0;
      margin: 64px 0px;
      @include responsive("mdDown") {
        font-size: 28px;
        line-height: 36px;
        margin: 64px 0px 24px 0px;
      }
    }
  }

  .CardWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    padding-bottom: 90px;
    @include responsive("mdDown") {
      grid-template-columns: 1fr;
      padding-bottom: 10px;
    }

    .cardViewWrapper {
      height: auto;
      max-width: 400px;
      background-color: $white-0;
      text-align: center;
      padding-top: 32px;
      @include responsive("mdDown") {
        max-width: 90%;
        margin: auto;
        margin-bottom: 24px;
      }

      .cardTitleWrapper {
        font-weight: 700;
        margin: 0px;
        color: #212529;
      }

      .cardtextWrapper {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        color: #212529;
        margin-bottom: 24px;
      }

      .cardImgWrapper {
        width: 85%;
        height: 332px;
      }

      .cardContentWrapper {
        color: #000000;
        margin: 24px 24px 32px 24px;
      }
    }
  }
}
