@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.isActive {
  @include responsive("mdDown") {
    display: block !important;
    width: 100% !important;
    .sidebarBurger {
      display: flex !important;
      align-items: center;
      justify-content: space-between !important;
      .closeIcon {
        display: block !important;
        padding: 20px;
        cursor: pointer;
      }
      i {
        font-size: 20px;
      }
    }
  }
}

.sidebar {
  z-index: 1;
  height: 100vh;
  position: fixed;
  inset: 0;
  background-color: $white-0;
  display: none;

  @include responsive("mdDown") {
    display: none;
  }
  .closeIcon {
    display: none;
  }
  .icon {
    display: flex;
    justify-content: flex-start;
    height: 80px;
    margin-left: 10px;
    margin-bottom: 20px;
  }
  .buttonsWrapperMobile {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-left: 20px;
    margin-bottom: 24px;

    .orderButton {
      background-color: $blue-0 !important;
    }
    .loginBtn {
      border: 1px solid $black-0 !important;
      color: $black-0 !important;
    }
  }

  .sidebarContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding-left: 20px;
    path {
      fill: #525f7f;
    }

    .active {
      color: $black-3 !important;
      path {
        fill: $black-3 !important;
      }
    }
    .socialLinks {
      display: flex;
      flex-direction: column;
      gap: 20px;
      a {
        color: $blue-2;
        text-decoration: none;
        height: 44px;
        display: flex;
        align-items: center;
        gap: 24px;
      }
    }

    .sidebarItem {
      display: flex;
      cursor: pointer;
      align-items: center;
      gap: 24px;
      height: 44px;
      color: $blue-2;
      text-decoration: none;
    }
  }
}
