@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  padding-top: 160px;
  padding-bottom: 150px;
  @include responsive("mdDown") {
    padding: 48px 0px 30px 0px;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    @include responsive("mdDown") {
      grid-template-columns: 1fr;
      padding-left: 24px;
      padding-right: 24px;
    }
    .content {
      color: $white-0;
      padding-right: 100px;
      text-align: left;
      @include responsive("mdDown") {
        padding-right: 0;
      }
      .title {
        font-family: "Inter Bold";
        font-size: 64px;
        line-height: 77px;
        margin-bottom: 32px;
        @include responsive("mdDown") {
          font-size: 48px;
          line-height: 64px;
        }
      }
      .info {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 32px;
        a {
          color: $primary-btn-color;
        }
      }
      .address {
        font-family: "Inter SemiBold";
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 0;
      }
    }
    .form {
      color: $white-0;
      .formGroup {
        text-align: left;
        .textareaStyling {
          height: 115px;
        }
        .label{
          font-size: 14px;
          margin-bottom: 8px !important;
        }
      }
      .sendButton {
        text-align: left;
        button {
          background-color: $blue-0 !important;
          @include responsive("mdDown") {
            width: 100%;
          }
        }
      }
    }
    .textWrapper {
      font-weight: 300;
      font-size: 13px;
      line-height: 16px;
      text-align: left;
      margin-top: 16px;
      .link{
        text-decoration: underline;
        color: $white-0;
        cursor: pointer;
      }
    }
  }
}
