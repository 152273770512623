@import "src/assets/scss/colors.scss";
@import "src/assets/scss/breakpoints.scss";

.container {
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include responsive("mdDown") {
    height: calc(100dvh - 260px);
  }
  .upperContainer {
    .title {
      color: $black-2;
      font-family: "Inter";
      font-size: 18px;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 32px;
      @include responsive("mdDown") {
        display: none;
      }
    }
    .reportContainer {
      .label {
        color: $blue-2;
        font-family: "Inter";
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.14px;
        margin-bottom: 8px;
        &::after {
          content: "*";
          color: $danger-btn-color;
          margin-left: 3px;
        }
      }
      .buttonGroup {
        display: flex;
        color: $black-3;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.14px;
        width: max-content !important;
        button {
          padding: 12px 16px;
        }
      }
    }
  }
  .nextStep {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
