@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  @include responsive("mdDown") {
    flex-direction: column-reverse;
  }

  .content {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include responsive("mdDown") {
      width: 100%;
    }
    svg {
      font-size: 34px;
      color: $blue-0;
      height: 34px;
      width: 34px;
    }
    .orderAction {
      background-color: $blue-0 !important;
    }
    .propertyInfo {
      .propertyInfoText {
        color: $blue-3;
      }
      .propertyContent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 32px;
        grid-row-gap: 24px;
        @include responsive("smDown") {
          grid-template-columns: 1fr;
        }
        .propertyType {
          text-transform: capitalize;
        }
      }
    }
    .insuranceInfo {
      .insuranceInfoText {
        color: $blue-3;
      }
      .insuranceInfoContent {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 32px;
        grid-row-gap: 24px;
        @include responsive("smDown") {
          grid-template-columns: 1fr;
        }
      }
    }
  }
  .map {
    width: 50%;
    @include responsive("mdDown") {
      width: 100%;
    }
    img {
      object-fit: cover;
      width: 100%;
      @include responsive("mdDown") {
        height: 380px;
      }
      @include responsive("smDown") {
        height: 150px;
      }
    }
  }

  .providedLabel {
    color: $input-placeholder-color;
  }
}
