@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.modalWrapper {
  max-width: 512px;
  .wrapper {
    .form {
      .actionButtons {
        display: flex;
        align-items: center;
        justify-content: left;
        gap: 24px;
        .submitButton {
          background-color: $blue-0 !important;
        }
      }

      .formGroup {
        text-align: start;
        .inputGroupStyle {
          box-shadow: none;
          border: 1px solid $white-4;
        }
        .textarea {
          height: auto;
        }
        .label {
          font-family: "Inter Meduim";
          font-size: 14px;
          color: $blue-2;
          margin-bottom: 8px !important;
          span {
            color: $danger-btn-color;
          }
        }
        label {
          font-family: "Inter Meduim";
          font-size: 14px;
          color: $blue-2;
          margin-bottom: 8px !important;
        }
      }
    }
  }
}
