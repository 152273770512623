@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

tr {
  border: 2px solid rgba(233, 236, 239,0.5);
  .address {
    font-family: "Inter Bold";
    font-size: 16px;
    color: $blue-2;
    margin-bottom: 0;
  }
  .orderDate {
    font-size: 14px;
    color: $blue-2;
    margin-bottom: 0;
  }
}
