@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
.active {
  background-color: $white-0 !important;
}
.wrapper {
  color: $black-0;
  background-color: $gray-5;
  padding: 56px 48px;
  width: 460px;
  height: 362px;
  @include responsive("mdDown") {
    padding: 56px 24px;
  }

  @include responsive("xsDown") {
    width: 327px;
    height: 379px;
  }

  .priceContent {
    height: 200px;
  }

  .title {
    font-family: "Inter Bold";
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 12px;
  }

  .colorCardTitle {
    font-family: "Inter";
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 12px;
  }

  .price {
    font-family: "Inter Bold";
    font-size: 64px;
    line-height: 56px;
    margin-bottom: 12px;
    .priceWrapper {
      font-size: 40px;
    }
  }

  .description {
    font-family: "Inter Light";
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 24px;
  }
  .reportDescription {
    margin-bottom: 32px;
  }

  .linkWrapper {
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 55px;
    display: flex;
    justify-content: center;
    width: 100%;
    .customButton {
      background-color: $blue-0 !important;
      width: fit-content;
    }
    .customLinkButton {
      padding: 12px 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-decoration: none;
      color: $white-0;
      background: #1f76fe;
      box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.21),
        0px 1px 3px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      background-color: $blue-0 !important;
      width: fit-content;
    }
  }
}
