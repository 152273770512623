@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.profileWrapper {
  height: fit-content;
  .profileHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    button {
      background-color: $blue-0 !important;
    }
  }

  svg {
    font-size: 34px;
    height: 34px;
    width: 34px;
    color: $blue-0;
  }
  .profileContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 24px;
    @include responsive("smDown") {
      grid-template-columns: 1fr;
    }
    .notProvided {
      color: $input-placeholder-color;
    }
  }
}