@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  .titleText {
    font-weight: 400;
    color: $white-0;
    margin-top: 18px;
  }

  .titleWrapper {
    font-weight: 700;
    color: $white-0;
    margin-bottom: 75px;
    @include responsive("mdDown") {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .highPlanTitle {
    color: $black-3;
    font-weight: 600;
    margin-bottom: 32px;
  }

  .planList {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr;
    grid-gap: 35px;
    @include responsive("mdDown") {
      grid-template-columns: 1fr;
    }
  }

  .lowerPlanTitle {
    color: $black-2;
    font-weight: 600;
    margin-top: 56px;
    margin-bottom: 32px;
  }

  .featuresWrapper {
    font-weight: 600;
    margin-top: 56px;
    color: $black-2;
  }

  .listWrapper {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    list-style-type: none;
    text-align: left;
    padding-left: 0px;
    color: $black-2;
  }

  .textWrapper {
    text-decoration: underline;
  }

  .linkWrapper {
    color: $black-2;
  }

  .subscriptionWrapper {
    font-weight: 600;
    margin-top: 56px;
    color: $black-2;
  }
}
