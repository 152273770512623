@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  background-color: $blue-3;
  padding-top: 120px;
  padding-bottom: 120px;
  @include responsive("mdDown") {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .container {
    color: $white-0;
    .title {
      font-size: 64px;
      line-height: 77px;
      @include responsive("mdDown") {
        margin-bottom: 24px;
      }
    }
    .description {
      font-family: "Inter Light";
      font-size: 24px;
      line-height: 29px;
    }
    .priceCards {
      display: flex;
      justify-content: center;
      gap: 32px;
      margin-bottom: 100px;
      margin-top: 100px;
      @include responsive("mdDown") {
        margin: 48px 0px;
        flex-direction: column;
        align-items: center;
      }
    }
    .newServiceText {
      font-family: "Inter Meduim";
      font-size: 36px;
      line-height: 44px;
      color: $yellow-1;
      @include responsive("mdDown") {
        margin-bottom: 24px;
      }
    }
    @include responsive("mdDown") {
      padding-left: 24px;
      padding-right: 24px;
      .title {
        font-size: 48px;
        line-height: 64px;
      }
      .description {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}
