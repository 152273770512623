@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url("./assets/fonts/Inter-Regular.ttf");
}
@font-face {
  font-family: "Inter Light";
  font-weight: 300;
  src: url("./assets/fonts/Inter-Light.ttf");
}
@font-face {
  font-family: "Inter Bold";
  font-weight: 700;
  src: url("./assets/fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "Inter SemiBold";
  font-weight: 600;
  src: url("./assets/fonts/Inter-SemiBold.ttf");
}
@font-face {
  font-family: "Inter ExtraBold";
  font-weight: 800;
  src: url("./assets/fonts/Inter-ExtraBold.ttf");
}
@font-face {
  font-family: "Inter Black";
  font-weight: 900;
  src: url("./assets/fonts/Inter-Black.ttf");
}
@font-face {
  font-family: "Inter Meduim";
  font-weight: 500;
  src: url("./assets/fonts/Inter-Medium.ttf");
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 0 !important;
  background-color: white !important;
}

*::-webkit-scrollbar-thumb {
  width: 0 !important;
  background-color: whitesmoke !important;
  border-radius: 0 !important;
}

*::-webkit-scrollbar:horizontal {
  width: 0 !important;
  background-color: white !important;
}

*::-webkit-scrollbar-thumb:horizontal {
  width: 0 !important;
  background-color: whitesmoke !important;
  border-radius: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
