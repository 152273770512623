@import "src/assets/scss/colors";
.loaderWrapper {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white-5;
  opacity: 0.3;
}
