@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.required {
  color: $danger-btn-color;
  margin-left: 3px;
}

.inputStyling {
  background: $white-0;
  border: none;
  color: $black-2;
  height: 46px;
  width: 100%;
  font-size: 14px;

  &:focus,
  &:active,
  &:focus-visible {
    border: none;
    box-shadow: none;
    color: $black-2;
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
  }

  &::placeholder {
    color: $input-placeholder-color;
    font-size: 14px;
  }
}

:global {
  .pac-container {
    border: 1px solid $white-4;
    box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
  }
}

.requiredLabel {
  margin-left: 3px;
  color: $danger-btn-color;
}

.isInvalid {
  border: 1px solid $warning-btn-color !important;
  outline: none !important;
}

.invalidFeedback {
  text-align: left;
  display: flex;
  color: $warning-btn-color;
  padding: 8px 12px;
  font-size: 14px;
}

.formGroupStyling {
  border: none;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);

  &:focus,
  &:focus-within,
  &:active,
  &:focus-visible {
    outline: 1px solid $blue-0;
    box-shadow: none;
    border: none;
  }
}

.iconStyling {
  background: $white-0;
  border: none;
  color: $input-placeholder-color;
}
