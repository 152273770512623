@import 'src/assets/scss/colors';

.buttonGroupStyles {
  border: 1px solid $white-3;
  button {
    border-right: 1px solid $white-3;
    border-left:  1px solid $white-3;
    &:first-child {
      border-left:  none;
    }
    &:last-child {  
      border-right: none;
    }
    &:hover, &:focus, &:active {
      border-right: 1px solid $white-3;
      border-left:  1px solid $white-3;
      &:first-child {
        border-left:  none;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
}

.isInvalid {
  border: 1px solid $warning-btn-color;
  outline: none;
}

.invalidFeedback {
  display: flex;
  color: $warning-btn-color;
  padding: 8px 12px;
}