@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.paymentInfo {
  .providedLabel {
    color: $input-placeholder-color;
  }
  svg {
    font-size: 34px;
    color: $blue-0;
    height: 34px;
    width: 34px;
  }
  .paymentInfoContent {
    display: flex;
    gap: 24px;
    @include responsive("mdDown") {
      flex-direction: column;
    }

    .shippingDetails {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 32px;
      @include responsive("mdDown") {
        width: 100%;
      }
    }
    .shippingAddress {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 32px;
      @include responsive("mdDown") {
        width: 100%;
      }
      img {
        height: 34px;
        width: 34px;
      }
      .link {
        .label {
          color: $input-placeholder-color;
        }
        svg {
          font-size: 16px;
        }
      }
    }
  }
}
