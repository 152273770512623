@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  min-height: calc(100vh - 90px);
  @include responsive("mdDown") {
    min-height: auto;
  }
  .container {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    height: 100%;
    color: $white-0;
    min-height: calc(100vh - 90px);

    @include responsive("mdDown") {
      grid-template-columns: 1fr;
      padding: 24px 24px 90px;
      min-height: auto;
    }
    .leftContent {
      text-align: left;
      .heading {
        font-size: 42px;
        line-height: 51px;
        span {
          font-family: Inter;
          font-weight: 400;
        }
      }
      .description {
        margin-bottom: 48px;
      }
      @include responsive("mdDown") {
        grid-row: 2;
        .heading {
          font-size: 30px;
          line-height: 44px;
          span {
            font-family: "Inter Bold";
          }
        }
        .description {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 24px;
        }
      }
      .reportButtons {
        display: flex;
        gap: 16px;
        margin-bottom: 40px;
        @include responsive("smDown") {
          flex-direction: column;
        }
        .outlineClass {
          color: $white-0;
          border: 1px solid $white-0;
        }
      }
      .links {
        color: $white-0;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
      }
      .colorLinks {
        margin-top: 24px;
      }
    }
    .media {
      text-align: -webkit-right;
      @include responsive("mdDown") {
        grid-row: 1;
      }

      .videoWrapper {
        position: relative;
        height: 287px;
        max-width: 509px;
        @include responsive("mdDown") {
          height: 211px;
          max-width: 100%;
        }
      }
    }
  }
}
