@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.header {
  background-color: $blue-0;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid rgba(255, 225, 225, 0.2);

  .dropdownToggleButton {
    background-color: transparent;
    color: $white-0;
    border: none;
    box-shadow: none;
  }

  .userDropdown {
    font-family: "Inter SemiBold";
    font-size: 10px;
    line-height: 14px;
    color: $blue-1;
  }

  .userDropdownItem {
    font-size: 14px;
  }

  .userProfile {
    display: flex;
    align-items: center;
    gap: 10px;

    .userImage {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      overflow: hidden;
      background-color: $white-0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .username {
      font-family: "Inter Meduim";
      font-style: normal;
      font-size: 14px;
      @include responsive("mdDown") {
        display: none;
      }
    }
  }
  .headerItem {
    display: flex;
    align-items: center;
    gap: 20px;

    button {
      i {
        background: -webkit-linear-gradient(90deg, #2DCE89 0%, #2DCECC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .burger {
      cursor: pointer;
      i {
        font-size: 18px;
        color: $white-0;
        display: none;
        @include responsive("mdDown") {
          display: block;
        }
      }
    }
  }
}