@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  .hideContainer {
    @include responsive("mdDown") {
      display: none !important;
    }
  }
  .mainContainer {
    position: relative;
    width: calc(100% - 260px);;
    display: flex;
    flex-direction: column;

    @include responsive("mdDown") {
      width: 100%;
    }
    .content {
      padding: 24px 30px;
      height: calc(100vh - 80px);
      overflow-y: auto;
      @include responsive("mdDown") {
        padding: 15px 15px;
      }
    }
  }
}
