@import "src/assets/scss/colors";

.defaultStatus {
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue-0;
  color: $white-0;
  width: 150px;
  height: 32px;
  text-transform: capitalize;
}

.awaiting_sample {
  background-color: $info-btn-color;
}

.shipment_received {
  background-color: $blue-3;
}

.lab_data_entry {
  background-color: $blue-0;
}

.pending_id {
  background-color: $blue-0;
}

.pending_approval {
  background-color: $blue-0;
}

.executive_review {
  background-color: $blue-0;
}

.manager_approved {
  background-color: $blue-0;
}

.pending_report {
  background-color: $blue-0;
}

.processing {
  background-color: $blue-0;
}

.complete {
  background-color: $success-btn-color;
}

.color_analysis {
  background-color: $blue-3;
}

.refunded {
  background-color: $danger-btn-color;
}

.draft {
  background-color: $blue-0;
}
