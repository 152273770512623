@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.dropdownToggleButton {
  background-color: transparent !important;
  color: $white-0 !important;
  border: none !important;
  box-shadow: none !important;
}

.userDropdown {
  font-family: "Inter SemiBold";
  font-size: 10px;
  line-height: 14px;
  color: $blue-1;
}

.userDropdownItem {
  font-size: 14px;
}

.userProfile {
  display: flex;
  align-items: center;
  gap: 10px;

  .userImage {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    overflow: hidden;
    background-color: $white-0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .username {
    @include responsive("mdDown") {
      display: none;
    }
  }
}