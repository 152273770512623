@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.introText {
  color: $white-0;
  text-align: left;
}

.address {
  color: $blue-2;
  margin-bottom: 0;
}

.buttonWrapper {
  display: flex;
  margin-bottom: 32px;
  gap: 30px;
  @include responsive("mdDown") {
    display: block;
  }
  .headerText {
    margin-bottom: 8px;
    color: $gray-1;
  }
}
.subscriptionName {
  text-transform: capitalize;
}

.reportType {
  color: $blue-2;
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  margin-bottom: 0;
}
