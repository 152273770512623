@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  .tableHeader {
    width: fit-content;
    tr {
      th:first-child {
        min-width: 110px;
      }
    }
  }
  .address {
    color: $black-3;
    margin-bottom: 0;
  }
  .noData {
    font-family: Inter;
    font-size: 16px;
    line-height: 20px;
    color: #ADB5BD
  }

  .trackingNo {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #adb5bd;
    .linkWrapper {
      color: $link;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .dropdown {
    margin-top: 4px;
  }

  .dropdownItem {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $black-3;
    margin-bottom: 12px;
  }
}
