@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.cardWrapper {
  background: rgba(0, 0, 0, 0.3);
  @include responsive("mdDown") {
    background: $white-0;
  }
  .title {
    margin: 0px;
    padding: 32px 24px 16px 24px;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }
  .cardBody {
    padding: 0px 24px 16px 24px;
  }
  .summaryWrapper {
    .items {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .subscriptionCredit{
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $yellow-1;
      @include responsive("mdDown") {
        color: $black-0;
      }
    }
    .promoCode {
      text-align: center;
      border-bottom: 1px solid $white-3;
      border-top: 1px solid $white-3;
      .enterCode {
        cursor: pointer;
      }
      .promoCodeInput {
        display: flex;
        justify-content: space-between;
        @include responsive("mdDown") {
          justify-content: start;
        }
        .errorMessage {
          color: $yellow-1;
          font-size: 14px;
          line-height: 17px;
          display: block;
          padding: 8px 10px;
          text-align: left;
          @include responsive("mdDown") {
            color: red;
          }
        }

        margin-top: 16px;
        .promoCodeInputStyle {
          width: 189px;
          height: 44px;
          border: none;
          border-radius: 4px;
          box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15),
            0 1px 0 rgba(0, 0, 0, 0.02);
          @include responsive("mdDown") {
            width: 30%;
          }
          &:focus,
          &:focus-within,
          &:active,
          &:focus-visible {
            outline: 1px solid $yellow-0 !important;
            box-shadow: none;
            border: none;
          }
        }
        .loadingBtn {
          padding-right: 10px;
          padding-left: 10px;
          @include responsive("mdDown") {
            width: 120px;
          }
        }
        .applyButton {
          height: 46px;
          @include responsive("mdUp") {
            border: 1px solid $white-0 !important;
            color: $white-0 !important;
          }
          @include responsive("mdDown") {
            margin-left: 10px;
          }
        }
      }
      .promoCodeWrapper {
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        color: $yellow-1;
        padding: 10px 0px;
        .boldText {
          margin-left: 3px;
        }
        @include responsive("mdDown") {
          color: red;
        }
      }
    }
  }
}
