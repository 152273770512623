@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  height: 90px;
  display: flex;
  align-items: center;
  @include responsive("smDown") {
    height: 65px;
    width: 100%;
    position: sticky;
    z-index: 1;
    top: 0;
  }
  .desktopContainer {
    @include responsive("mdDown") {
      max-width: 100%;
    }
    .buttonsWrapper {
      gap: 20px;

      .orderButton {
        background-color: $blue-0 !important;
      }
    }

    @include responsive("mdDown") {
      display: none !important;
    }
  }
  .homePageMobileHeader {
    display: none;
    @include responsive("mdDown") {
      width: 100%;
      position: sticky;
      z-index: 1;
      top: 0;
      background-color: $white-0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 16px;
      padding-right: 16px;
      .hamburger {
        cursor: pointer;
        svg {
          font-size: 18px;
        }
      }
    }
  }

  .logoWrapper {
    cursor: pointer;
  }

  .tabsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 36px;
    .links {
      font-family: "Inter Meduim";
      font-size: 14px;
      line-height: 17px;
      color: $black-0;
      text-decoration: none;
    }
    .socialLinks {
      display: flex;
      align-items: center;
      gap: 18px;
      a {
        color: $black-2;
      }
    }
  }
}
