@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.progressBarMobile {
  display: none;
  @include responsive("mdDown") {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .progressBar {
    margin-bottom: 32px;
    display: flex;
    gap: 4px;
    .mobileBarWrapper {
      width: 100%;
      p {
        color: $input-placeholder-color;
      }
      .bar {
        border-radius: 1px;
        width: 100%;
        height: 5px;
        background: $gray-3;
      }
    }
    .active {
      .bar {
        border-radius: 1px;
        width: 100%;
        height: 5px;
        background: linear-gradient(90deg, #2dce89 0%, #2dcecc 100%);
      }
      p {
        color: $black-0;
      }
    }
  }
}