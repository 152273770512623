@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.customerWrapper {
  .actionIcons {
    padding-right: 10px;
    .orderButton {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border: none;
        border-radius: 50%;
        background: linear-gradient(90deg, #fb6340 0%, #fbb140 100%);
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .disable {
      opacity: 0.5;
      pointer-events: none;
    }
    .email {
      cursor: pointer;
      color: $primary-btn-color;
    }
    .edit {
      cursor: pointer;
      color: $info-btn-color;
    }
    .delete {
      cursor: pointer;
      color: $danger-btn-color;
    }
  }
  table {
    thead {
      tr {
        th {
          &:last-child {
            text-align: end;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          cursor: auto;
        }
      }
    }
  }
  .companyName {
    padding: 7px 12px;
  }
  .noData {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #adb5bd;
  }
  .subscriptionDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    .buttonWrapper {
      min-width: 157px;
      text-align: end;
    }
    button {
      background-color: $blue-0 !important;
    }
  }
}
