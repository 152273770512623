@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  height: 100%;
  @include responsive("smDown") {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
  .resetPasswordHeader {
    padding-top: 60px;
    padding-bottom: 76px;
    @include responsive("smDown") {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .logo {
      display: none;
      img {
        height: 136px;
        width: 136px;
        @include responsive("smDown") {
          height: 102px;
          width: 102px;
        }
      }
      @include responsive("smDown") {
        display: block;
      }
    }

    p {
      font-family: "Inter Meduim";
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      color: $black-2;
      @include responsive("smDown") {
        display: none;
      }
    }
  }

  .card {
    max-width: 446px;
  }

  .buttonWrapper {
    margin-bottom: 24px;
    margin-top: 16px;

    .setPassword {
      width: 150px;
      height: 44px;
      padding: 0;
    }
  }
}
