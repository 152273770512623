@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  .breadcrumbWrapper {
    margin-bottom: 36px;
    gap: 24px;
    display: flex;
    align-items: center;
    color: $white-0;
  }
}

.breadcrumbNoOrderWrapper {
  margin-bottom: 36px;
  gap: 24px;
  display: flex;
  align-items: center;
  color: $white-0;
}

.noOrders {
  height: 100%;
  display: flex;
  align-items: center;
  @include responsive("mdDown") {
    height: fit-content;
  }

  .card {
    max-width: 512px !important;
  }

  .title {
    color: $black-1;
    font-family: "Inter SemiBold" !important;
    line-height: 29px !important;
    font-size: 24px !important;
  }
  .cardBody {
    padding-top: 0px !important;
    padding-bottom: 56px !important;
    button {
      background-color: $blue-0 !important;
    }
  }
}
