@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  background-color: $gray-4;
  .nav {
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    background-color: $blue-0;
    padding: 0 24px;
    color: $white-0;
    top: 0;
    z-index: 999;

    .backToDashboard {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
    }
    .orderText {
      @include responsive("mdDown") {
        display: none;
      }
    }
  }

  .checkoutContentWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 30px;
    min-height: calc(100vh - 80px);
    overflow-y: auto;
    @include responsive("mdDown") {
      padding: 15px 15px;
      align-items: flex-start;
    }
  }
}
