@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.formWrapper {
  .caret {
    color: $input-placeholder-color;
    position: absolute;
    right: 8px;
  }
  .locationDetails {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-column-gap: 16px;
    @include responsive("mdDown") {
      grid-template-columns: 1fr;
    }
  }
  .formGroup {
    width: 100%;
    text-align: start;

    .inputGroupStyle {
      box-shadow: none;
      border: 1px solid $white-4;
    }
    .buttonWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 16px;
      @include responsive("mdDown") {
        grid-template-columns: 1fr;
        grid-row-gap: 16px;
        .sqftFieldWrapper {
          width: 100%;
        }
      }
      .buttonGroup {
        button {
          padding: 13px 16px;
        }
      }
    }

    .label {
      font-family: "Inter Meduim";
      font-size: 14px;
      line-height: 22px;
      color: $blue-2;
      margin-bottom: 8px !important;
      span {
        color: $danger-btn-color;
      }
    }
    label {
      font-family: "Inter Meduim";
      font-size: 14px;
      color: $blue-2;
      margin-bottom: 8px !important;
    }
  }
}
