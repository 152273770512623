@import 'src/assets/scss/colors';

.h1{
  font-family: "Inter Bold";
  font-size: 48px;
  line-height: 64px;
}

.h2{
  font-family: "Inter Bold";
  font-size: 32px;
  line-height: 44px;
}

.h3{
  font-family: "Inter SemiBold";
  font-size: 24px;
  line-height: 24px;
}

.h4{
  font-family: "Inter SemiBold";
  font-size: 18px;
  line-height: 22px;
}

.h5{
  font-family: "Inter Bold";
  font-size: 14px;
  line-height: 18px;
}

.h6{
  font-family: "Inter SemiBold";
  font-size: 12px;
  line-height: 16px;
}

.p10{
  font-size: 10px;
  line-height: 14px;
}

.p12{
  font-size: 12px;
  line-height: 16px;
}

.p13{
  font-size: 13px;
  line-height: 16px;
}

.p14{
  font-size: 14px;
  line-height: 18px;
}

.p16{
  font-size: 16px;
  line-height: 20px;
}

.p18{
  font-size: 18px;
  line-height: 22px;
}

.p20{
  font-size: 20px;
  line-height: 24px;
}

.p24{
  font-size: 24px;
  line-height: 32px;
}

.p28{
  font-size: 28px;
  line-height: 36px;
}

.p32{
  font-size: 32px;
  line-height: 44px;
}

.p42{
  font-size: 42px;
  line-height: 50px;
}

.p48{
  font-size: 48px;
  line-height: 64px;
}

.light{
  font-family: 'Inter Light';
}

.regular{
  font-family: 'Inter';
}

.medium{
  font-family: 'Inter Meduim';
}

.semibold{
  font-family: 'Inter SemiBold';
}

.bold{
  font-family: 'Inter Bold';
}

