.carets {
  display: inline-flex;
  flex-direction: column;
  margin-left: 4px;
  vertical-align: top;
  i {
    height: 6px;
    width: 6px;
  }
}
