@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  height: 100%;
  @include responsive("smDown") {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
  .card {
    max-width: 446px;
  }

  .signInHeader {
    padding-top: 61px;
    padding-bottom: 40px;
    @include responsive("smDown") {
      padding-top: 0;
      padding-bottom: 0;
    }
    .logo {
      display: none;
      @include responsive("smDown") {
        margin-top: 20px;
        display: block;
      }
    }
    img {
      height: 136px;
      width: 136px;
      @include responsive("smDown") {
        height: 102px;
        width: 102px;
      }
    }
    .title {
      p {
        font-family: "Inter Meduim";
        font-size: 32px;
        line-height: 44px;
        text-align: center;
        color: $black-2;
        @include responsive("smDown") {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 32px;
        }
      }
    }
  }

  .checkEmailPage {
    padding-bottom: 100px;
  }

  .footer {
    font-weight: 600;
  }
  .link {
    color: $primary-btn-color;
  }
  .signInInputs {
    .formGroup {
      @include responsive("smDown") {
        margin-bottom: 12px !important;
      }
    }
    .formGroupCheckBox {
      font-size: 14px;
      @include responsive("smDown") {
        margin-top: 12px !important;
        margin-bottom: 0 !important;
      }
    }
    .forgotPassword {
      text-align: left;
      a {
        text-decoration: none;
      }
      p {
        font-size: 14px;
        font-weight: 600;
        color: $primary-btn-color;
      }
    }
    .signInButton {
      width: 150px;
    }
  }

}
