@import "src/assets/scss/colors";

.dropDownStyling {
  .dropdownToggle {
    background: $white-0;
    color: $black-2;
    width: 100%;
    border: 1px solid $white-4;
    box-shadow: 0px 50px 100px rgba(50, 50, 93, 0.1),
      0px 15px 35px rgba(50, 50, 93, 0.15), 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .dropdownMenu {
    width: 100%;
    border: 1px solid $white-4;
    box-shadow: 0px 50px 100px rgba(50, 50, 93, 0.1),
      0px 15px 35px rgba(50, 50, 93, 0.15), 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    .dropdownItem {
      .icon {
        font-size: 16px;
        margin-right: 20px;
      }
      &:hover {
        background-color: $white-2;
      }

      &:active {
        color: $black-0;
        background-color: $white-3;
      }
      text-align: center;
    }
  }
}
