@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
.noData {
  position: absolute;
  inset: 0;
  font-family: "Inter SemiBold";
  font-size: 24px;
  line-height: 32px;
  color: gray;
}

.wrapper {
  background: $white-0;
  box-shadow: 0 0 32px rgba(136, 152, 170, 0.15);
  border-radius: 6px;
  border: none;
  overflow: hidden;

  .tableWrapper {
    @include responsive("mdDown") {
      overflow-x: auto;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .dataShow {
    }
    .loader {
      padding: 30px;
    }

    .paginationButtons {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .onPage {
        color: #3ba5c0;
        border-bottom: 1px solid #3ba5c0;
      }

      button {
        background-color: #ffff;
        border: none;
        cursor: pointer;
        img {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      .hide {
        visibility: hidden;
      }
    }

    .pageIndex {
      button {
        margin-right: 5px;
        margin-left: 5px;
        background-color: #ffff;
        border: none;
        cursor: pointer;
      }
    }
  }
  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 68px;
    padding: 20px 24px;
    gap: 20px;
    @include responsive("xsDown") {
      flex-direction: column;
      gap: 20px;
      align-items: baseline;
    }
    .tableHeading {
      display: flex;
      align-items: center;
      gap: 32px;
      .seeAll {
        button {
          background-color: $blue-0 !important;
        }
      }
    }
    .search {
      max-width: 313px;
      width: 100%;
      border: 1px solid #dee2e6;
      box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
      border-radius: 4px;
      .searchInput {
        &:focus,
        &:focus-within,
        &:active,
        &:focus-visible {
          outline: 1px solid $primary-btn-color;
          box-shadow: none;
          border: none;
        }
      }
    }
  }

  table {
    width: 100%;
    text-align: left;
    border-spacing: 0px;
    @include responsive("mdDown") {
      min-width: 1200px;
    }

    thead {
      background-color: $white-1;
      tr {
        border-style: hidden;
        @include responsive("mdDown") {
          min-width: 1300px !important;
        }
        th {
          cursor: pointer;
          font-family: "Inter SemiBold";
          font-size: 11px;
          color: $gray-1;
          padding: 14px 24px;
          align-items: center;
          text-transform: uppercase;
        }
      }
    }
    .tableBody {
      &.showEmpty {
        height: calc(100vh - 300px);
      }
    }

    tbody {
      position: relative;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      tr {
        td {
          cursor: pointer;
        }
      }
    }

    th {
      align-items: center;
      padding: 20px 20px 23px 20px;
      text-transform: uppercase;
    }

    td {
      padding: 14px 24px;
      border-bottom: 1px solid #c4c4c4;
    }
  }
}

.dropDownStyling {
  border: none !important;

  .dropdownToggle {
    background: $white-0;
    color: $black-2;
    width: 100%;
    border: none !important;
    padding: 0px !important;
    .btn {
      background: $blue-0 !important;
      padding: 6px 12px !important;
      border: none;
      border-radius: 6px;
      font-size: 14px;
      line-height: 20px;
      font-family: "Inter Meduim";
    }
  }

  .dropdownMenu {
    border: 1px solid $white-4;
    box-shadow: 0px 50px 100px rgba(50, 50, 93, 0.1),
      0px 15px 35px rgba(50, 50, 93, 0.15), 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    margin-top: 4px;
    .dropdownItem {
      font-size: 14px;
      line-height: 20px;
      font-family: "Inter Meduim";
      justify-content: flex-start;
      display: flex;
      .icon {
        font-size: 16px;
        margin-right: 20px;
      }
      &:hover {
        background-color: $white-2;
      }

      &:active {
        color: $black-0;
        background-color: $white-3;
      }
      text-align: center;
    }
  }
}
