@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  padding-top: 190px;
  padding-bottom: 190px;
  @include responsive("mdDown") {
    padding-top: 48px;
    padding-bottom: 36px;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    @include responsive("mdDown") {
      grid-template-columns: 1fr;
      padding-left: 24px;
      padding-right: 24px;
    }
    .content {
      color: $white-0;
      text-align: left;
      padding-right: 105px;

      @include responsive("mdDown") {
        padding-right: 0;
      }
      .title {
        font-family: "Inter Bold";
        font-size: 64px;
        line-height: 77px;
        margin-bottom: 32px;
        @include responsive("mdDown") {
          font-size: 48px;
          line-height: 64px;
          margin-bottom: 24px;
        }
      }
      .description {
        font-family: "Inter Light";
        font-size: 24px;
        line-height: 29px;
        a {
          color: $primary-btn-color;
        }
      }
    }
    .accordionWrapper {
    }
  }
}
