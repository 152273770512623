.wrapper {
  text-align: left;
  display: flex;
  align-items: center;
  gap: 16px;
  .label {
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    font-family: "Inter";
    .input {
      margin-right: 4px;
      margin-top: 0;
    }
  }
}
