@import 'src/assets/scss/colors';

.buttonStyling {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter Meduim";
  .leftIconStyling {
    margin-right: 10px;
  }

  .rightIconStyling {
    margin-left: 10px;
  }

  border: none;
}

.link {
  display: inline-flex;
  gap: 4px;
}

.linkStyling {
  border: none !important;
}

.md {
  padding: 12px 20px;
}

.sm {
  padding: 7px 12px;
}

.disabled {
  &:disabled {
    opacity: 0.4 !important;

  }
}

//btn-fill

.primary-fill {
  background-color: $primary-btn-color !important;

  &:hover, &:focus, &:active {
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
    color: $white-0;
    background-color: $primary-btn-color;
    border: none;
  }
}

.secondary-fill {
  color: $black-0;
  background-color: $secondary-btn-color !important;

  &:hover, &:focus, &:active {
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
    background-color: $secondary-btn-color;
    color: $black-0 ;
    border: none;
  }
  &:disabled {
    color: $black-0 ;
  }
}

.info-fill {
  background-color: $info-btn-color !important;

  &:hover, &:focus, &:active {
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
    color: $white-0;
    background-color: $info-btn-color;
    border: none;
  }
}

.success-fill {
  background-color: $success-btn-color !important;

  &:hover, &:focus, &:active {
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
    color: $white-0;
    background-color: $success-btn-color;
    border: none;
  }
}

.danger-fill {
  background-color: $danger-btn-color !important;

  &:hover, &:focus, &:active {
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
    color: $white-0;
    background-color: $danger-btn-color;
    border: none;
  }
}

.warning-fill {
  background-color: $warning-btn-color !important;

  &:hover, &:focus, &:active {
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11), 0px 1px 3px rgba(0, 0, 0, 0.08);
    color: $white-0;
    background-color: $warning-btn-color;
    border: none;
  }
}

//btn-outline

.primary-outline {
  border: 1px solid $primary-btn-color !important;
  color: $primary-btn-color !important;
  background-color: transparent !important;

  &:hover, &:focus, &:active {
    border: 1px solid $primary-btn-color;
    background-color: $primary-btn-color;
    color: $white-0;
    box-shadow: none;
  }
}

.secondary-outline {
  border: 1px solid $black-0;
  color: $black-0;
  background-color: transparent !important;

  &:hover, &:focus, &:active {
    border: 1px solid $black-0;
    background-color: $black-0;
    color: $black-0;
    box-shadow: none;
  }
}

.info-outline {
  border: 1px solid $info-btn-color !important;
  color: $info-btn-color !important;
  background-color: transparent !important;

  &:hover, &:focus, &:active {
    border: 1px solid $info-btn-color;
    background-color: $info-btn-color;
    color: $white-0;
    box-shadow: none;
  }
}

.success-outline {
  border: 1px solid $success-btn-color !important;
  color: $success-btn-color !important;
  background-color: transparent !important;

  &:hover, &:focus, &:active {
    border: 1px solid $success-btn-color;
    background-color: $success-btn-color;
    color: $white-0;
    box-shadow: none;
  }
}

.danger-outline {
  border: 1px solid $danger-btn-color !important;
  color: $danger-btn-color !important;
  background-color: transparent !important;

  &:hover, &:focus, &:active {
    border: 1px solid $danger-btn-color;
    background-color: $danger-btn-color;
    color: $white-0;
    box-shadow: none;
  }
}

.warning-outline {
  border: 1px solid $warning-btn-color !important;
  color: $warning-btn-color !important;
  background-color: transparent !important;

  &:hover, &:focus, &:active {
    border: 1px solid $warning-btn-color;
    background-color: $warning-btn-color;
    color: $white-0;
    box-shadow: none;
  }
}


.primary-link {
  color: $primary-btn-color;
  cursor: pointer;

  &:hover, &:focus, &:active {
    text-decoration: underline;
  }
}

.secondary-link {
  color: $black-0;
  cursor: pointer;

  &:hover, &:focus, &:active {
    text-decoration: underline;
  }
}

.info-link {
  color: $info-btn-color;
  cursor: pointer;

  &:hover, &:focus, &:active {
    text-decoration: underline;
  }
}

.success-link {
  color: $success-btn-color;
  cursor: pointer;

  &:hover, &:focus, &:active {
    text-decoration: underline;
  }
}

.danger-link {
  color: $danger-btn-color;
  cursor: pointer;

  &:hover, &:focus, &:active {
    text-decoration: underline;
  }
}

.warning-link {
  color: $warning-btn-color;
  cursor: pointer;

  &:hover, &:focus, &:active {
    text-decoration: underline;
  }
}