@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  margin: 60px 0px;
  text-align: left;
  color: #212529;
  @include responsive("mdDown") {
    margin: 60px 24px;
  }
  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    margin-bottom: 32px;
  }
  .subTitle {
    font-weight: 600;
    margin-top: 32px;
  }

  ol {
    counter-reset: list-counter;
    list-style-type: none;

    li {
      position: relative;
      counter-increment: list-counter;
      &::before {
        font-size: 20px;
        left: -30px;
        top: -3px;
        position: absolute;
        content: counter(list-counter, lower-alpha) ") ";
      }
    }
  }
}
