@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.cardWrapper {
  background: rgba(0, 0, 0, 0.3);
  height: fit-content;
  @include responsive("mdDown") {
    background: $white-0;
  }
  .title {
    margin: 0px;
    padding: 32px 24px 16px 24px;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }
  .cardBody {
    padding: 0px 24px 16px 24px;
  }
  .cardText {
    color: $yellow-1;
    @include responsive("mdDown") {
      color: $black-0;
    }
  }
}
