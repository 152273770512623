@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.mapStyle {
  position: relative;
  height: 387px;
  width: 100%;
  @include responsive("mdDown") {
    height: 380px;
  }
  @include responsive("smDown") {
    height: 150px;
  }
}