@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.contactInfo {
  svg {
    font-size: 34px;
    height: 34px;
    width: 34px;
    color: $blue-0;
  }
  .contactInfoContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
    grid-row-gap: 24px;
    @include responsive("smDown") {
      grid-template-columns: 1fr;
    }
  }
  .providedLabel {
    color: $input-placeholder-color;
  }
}
