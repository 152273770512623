@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  height: 90px;
  @include responsive("smDown") {
    display: none !important;
  }

  .buttonsWrapper {
    gap: 20px;
  }

  .logoWrapper {
    cursor: pointer;
  }

  .tabsWrapper {
    a {
      font-size: 14px;
      color: $black-2;
      cursor: pointer;

      &:hover {
        color: $black-2;
      }
    }
  }
}