@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.profileEditWrapper {
  svg {
    color: $blue-0;
  }
  .inputStyle {
    border: 1px solid #DEE2E6;
    box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
  }
  .profileInputLabel {
    font-family: "Inter Meduim";
    color: $blue-2;
    width: 100%;
    text-align: left;
  }
  .actionButton {
    text-align: left;
    display: flex;
    gap: 16px;
    .saveButton {
      border: none;
      background-color: $blue-0;
    }
  }
}
